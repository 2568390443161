//Sidbar Navigation
const SIDEBAR_NAVIGATION = [
    { name: 'Файлы', to: '/files', role: ['superadmin', 'admin', 'editor', 'user', '1', '2']},
    { name: 'Категории', to: '/categories', role: ['superadmin', 'admin', 'editor', 'user', '1', '2']},
    { name: 'Пользователи', to: '/users', role: ['superadmin', 'admin', '1'] },
    { name: 'Компания', to: '/company', role: ['superadmin', 'admin', 'editor', 'user', '1', '2'] },
    { name: 'События', to: '/events', role: ['superadmin', 'admin', 'editor', 'user', '1', '2'] },
    { name: 'Сообщения пользователей', to: '/user-posts', role: ['superadmin', 'admin', 'editor', 'user', '1', '2'] },
]

export {
    SIDEBAR_NAVIGATION,
}