<template lang="pug">
    .container.p-3
        ul
            template(v-for="(item, index) in SIDEBAR_NAVIGATION")
                template(v-if="item.role.includes(getUserAuthData.role)")
                    li.mb-3 
                        router-link(
                            :to="item.to"
                            class="text-sm font-light cursor-pointer hover:underline"
                        ) 
                            span {{ item.name }}
</template>

<script>
//Configs
import { SIDEBAR_NAVIGATION } from '@/configs/navigation.js'
//Helpers
import { getUserAuthData } from '@/helpers/authorization';

export default {
    name: "Navigation",
    computed: {
        SIDEBAR_NAVIGATION() {
            return SIDEBAR_NAVIGATION
        },
        getUserAuthData() {
            return getUserAuthData()
        }
    }
}
</script>

<style lang="scss" scoped>
.router-link-active {
    color: #2563eb;
    position: relative;
}
</style>