<template lang="pug">
    .w-screen.h-screen.grid.grid-rows-layout.border
        header.w-full.pl-3.pr-3.flex.items-center.justify-between.bg-gray-100
            section
                slot(name="header-left")
            section
                slot(name="header-center")
            section
                slot(name="header-right")
        section.w-full.flex.bg-gray-100
            slot(name="main-content")
        footer.w-full.flex.items-center.justify-between.border
            slot(name="footer-left")
            slot(name="footer-center")
            slot(name="footer-right")
</template>

<script>


export default {
    name: "MainLayout",
}
</script>