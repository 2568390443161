<template lang="pug">
    ul.flex.gap-5.text-sm
        template(v-for="(item, index) in items")
            router-link(:to="item.route" :key="index")
                li {{ item.name }}
            span(v-if="index !== items.length - 1") |
</template> 

<script>
export default {
    name: "TabNavigation",
    props: {
        items: {
            type: Array,
            default: () => [],
        }
    }
}
</script>

<style lang="scss" scoped>
.router-link-exact-active {
    color: #2563eb;
}
</style>