<template lang="pug">
    .container
        span.inline-block.text-accent MY
        span.inline-block CMS
</template>

<script>
export default {
    name: "Logo",
}
</script>