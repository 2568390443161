<template lang="pug">
    main-layout
        // - header
        template(v-slot:header-left)
            logo
        template(v-slot:header-center)
        template(v-slot:header-right)
            .flex.gap-5
              span.text-sm {{ getUserAuthData.email }}
              span(@click="logOut" class="hover:underline text-sm text-accent cursor-pointer") {{ BUTTONS_VALUES.log_out }}
        // - main content
        template(v-slot:main-content)
            section.w-60.border
                navigation
            section.w-full.p-4.border
                slot(name="main-content")
        // - footer
        template(v-slot:footer-left)
            section 
        template(v-slot:footer-center)
            section 
        template(v-slot:footer-right)
            section 
</template>

<script>
//Store
import { AuthorizationNameSpace, AuthorizationActionTypes } from '@/store/authorization/types';
//Components
import MainLayout from './main-layout.vue';
import Logo from '@/components/common/logo/Logo';
import Navigation from '@/components/common/navigation';
//Helpers
import { logOut, getUserLoginStatus } from '../helpers/authorization';
import { getUserAuthData } from '@/helpers/authorization';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js'

export default {
  name: "AuthorizedLayout",
  components: {
      'main-layout': MainLayout,
      'logo': Logo,
      'navigation': Navigation,
  },
  data() {
    return {
      pinging: null,
    }
  },
  computed: {
    BUTTONS_VALUES() {
      return BUTTONS_VALUES
    },
    getUserAuthData() {
      return getUserAuthData()
    }
  },
  created() {
    // if (this.getUserLoginStatus()) this.pingAuth()
  },
  methods: {
    pingAuth() {
      this.pinging = setInterval(() => {
        this.$store.dispatch(`${AuthorizationNameSpace}/${AuthorizationActionTypes.PingAuth}`)
      }, 60000)
    },
    logOut() {
        return logOut()
    },
    getUserLoginStatus() {
        return getUserLoginStatus()
    }
  },
  beforeDestroy() {
    clearInterval(this.pinging)
  },
}
</script>